
import React from "react"

const Supply = () => (
  <section className="supply">
  <div className="supply__inner">
    <h2>Industries we Work With</h2>
    <h4>We work in a wide range of market sectors including:</h4>
    <div className="supply__list">
      <ul>
      <li>Winery refrigeration needs</li>
      <li>Plastic process Cooling</li>
      <li>Food processing</li>
      <li>Vegetable Processing</li>
      <li>Sea Food</li>
      </ul>

      <ul>
        <li>Vegetable Storage</li>
        <li>Industrial cool rooms</li>
        <li>Concrete Batching Cooling</li>
        <li>Dairy cooling</li>
        <li>Pharmaceutical industry</li>
        <li>Dry Cleaning chillers</li>
      </ul>

      <ul>
        <li>Distilleries</li>
        <li>Mining Refrigeration</li>
        <li>Fruit Juice cooling</li>
        <li>Brewery refrigeration</li>
        <li>Temperature controlled Storage & more</li>
      </ul>
    </div>
  </div>
</section>
)


export default Supply

import React from "react"

const Product = () => (

<section className="product">
<div className="product__inner">
<div className="product__list">
  <h2>Products</h2>
  <p>
    Quality supplier of specialised refrigeration equipment: 
    Water Chillers, Glycol Chillers, Condensing units.
  </p>

  <ul>
    <li>Specific refrigeration equipment to meet demanding requirements.</li>
    <li>Specialised Glycol Chiller Units</li>
    <li>Natural Refrigerant: chillers and refrigeration equipment</li>
    <li>High Efficiency equipment and solutions for existing equipment</li>
    <li>Purpose designed pump skids and tanks.</li>
  </ul>

  <p>We offer a variety of plant configuration options to meet site conditions including Remote condensers,
Remote evaporators, Compressor set units.</p>

  <p>Ancillary equipment and design is provided for pump selection and application, 
    a range of water tank configuration and designs, energy recovery equipment to suit 
    the equipment, process to water heat exchangers and additional air and water cooling heat exchangers.
  </p>
</div>

<picture className="product__img">
<img src={require("../images/product.jpg")} alt="product" />
</picture>
</div>
</section>
)


export default Product

import React from "react"

const Footer = () => (
  <footer>
    <p>Need a cooling solution? Call us now <a href='tel:+61397736459'>+61 3 9773 6459</a></p>
  </footer>
)


export default Footer

import React from "react"

const BlueSymbol = () => (
  <section class="blue-symbol">

    <img src={require("../images/drop-icon.svg")} alt="drop-icon" />

  </section>


)


export default BlueSymbol 

import React from "react"



const WhatOffer = () => (
 
 
  <section className="what-offer">
      <div className="what-offer__inner">
        <h2>What We Offer</h2>
        <p>For over 30 years Serchill has worked with our clients to design, build and service their Glycol and chilled water refrigeration systems.</p>
        <p>Our clients trust us to understand their needs and design solutions that meet and exceed their expectations.</p>
        <p>We strive to understand our customers specific needs and work alongside them from the concept stage of each project. Through the sharing of ideas and knowledge, we generate a tailored solution that delivers the best value for our customers.</p>
      </div>

  </section>


)


export default WhatOffer







import React from "react"

const OurServices = () => (

<section className="our-services">
<div className="our-services__inner">
  <picture>
  <img src={require("../images/our-services.jpg")} alt="services" />
  </picture>

  <div className="our-services__list">
    <h2>Our Services</h2>
    <p>
      We design systems that are easy to maintain and use the latest technology equipment to suit the application. Maintaining the best available energy efficiency and environmental impact of refrigeration systems is always at the forefront of our designs. <br/>
      Our fields of expertise include Process Low temperature glycol, Process chilled water systems, Hybrid and specialised refrigeration systems, Industrial air drying and cooling, General Refrigeration Systems, Warehouse and Wine store cooling and conditioning.
    </p>
    <ul>
      <li>Design Consultation</li>
      <li>Project Management</li>
      <li>Commissioning</li>
      <li>Trouble shooting</li>
      <li>Service & Repairs</li>
      <li>Maintenance</li>
      <li>Pre Season Checks</li>
    </ul>
  </div>
</div>
</section>
)


export default OurServices
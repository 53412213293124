import Slider from 'react-slick'
import React, { Component } from 'react';

import Slide1 from '../images/SC004-image1.jpg'
import Slide2 from '../images/SC004-image2.jpg'
import Slide3 from '../images/SC004-image3.jpg'
import Slide4 from '../images/SC004-image4.jpg'

export default class Welcome extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      pauseOnFocus: false,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <section className='welcome'>
        <Slider {...settings}>
          <div className="welcome__slide">
            <div className="welcome__bg" style={{ backgroundImage: `url(${Slide1})` }} />

            <div className="welcome__slide__inner">
              <h1>Glycol Chilling Systems</h1>
              <h2> Design through to Commissioning</h2>
              <p>Let us help with a Cooling System call <a href='tel:+61397736459'>+61 3 9773 6459</a></p>
              <a href='mailto:info@serchill.com.au' className="email">Email Us Today</a>
            </div>

          </div>
          <div className="welcome__slide">

            <div className="welcome__bg" style={{ backgroundImage: `url(${Slide2})` }} />

            <div className="welcome__slide__inner">
              <h1>Glycol Chilling Systems</h1>
              <h2> Design through to Commissioning</h2>
              <p>Let us help with a Cooling System call <a href='tel:+61397736459'>+61 3 9773 6459</a></p>
              <a href='mailto:info@serchill.com.au' className="email">Email Us Today</a>
            </div>

          </div>
          <div className="welcome__slide">

            <div className="welcome__bg" style={{ backgroundImage: `url(${Slide3})` }} />

            <div className="welcome__slide__inner">
              <h1>Glycol Chilling Systems</h1>
              <h2> Design through Commissioning</h2>
              <p>Let us help with a Cooling System call <a href='tel:+61397736459'>+61 3 9773 6459</a></p>
              <a href='mailto:info@serchill.com.au' className="email">Email Us Today</a>
            </div>

          </div>

          <div className="welcome__slide">

            <div className="welcome__bg" style={{ backgroundImage: `url(${Slide4})` }} />

            <div className="welcome__slide__inner">
              <h1>Glycol Chilling Systems</h1>
              <h2> Design through Commissioning</h2>
              <p>Let us help with a Cooling System call <a href='tel:+61397736459'>+61 3 9773 6459</a></p>
              <a href='mailto:info@serchill.com.au' className="email">Email Us Today</a>
            </div>

          </div>

        </Slider>
      </section>
    );
  }
}

import React from "react"

const Brands = () => (

  <section className="brands">
    <div className="brands__inner">
      <h2>Brands We Use</h2>
      <div className="brands__icons">
        <picture>
          <img src={require("../images/Bitzer.jpg")} alt="brands-1" />
        </picture>
        <picture>
          <img src={require("../images/Bluebox.jpg")} alt="brands-2"/>
        </picture>
        <picture>
          <img src={require("../images/Ebara.jpg")} alt="brands-3"/>
        </picture>
        <picture>
          <img src={require("../images/Eurothermal.jpeg")} alt="brands-4"/>
        </picture>
        <picture>
          <img src={require("../images/Lowara.jpg")} alt="brands-5"/>
        </picture>
        <picture>
          <img src={require("../images/Mta.jpg")} alt="brands-6"/>
        </picture>
        <picture>
          <img src={require("../images/Quantum.jpg")} alt="brands-5"/>
        </picture>
        <picture>
          <img src={require("../images/Sondex.jpg")} alt="brands-6"/>
        </picture>
      </div>
    </div>
  </section>
  

)

export default Brands
import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Welcome from "../components/welcome"
import Footer from "../components/footer"
import WhatOffer from "../components/what-offer"
import BlueSymbol from "../components/blue-symbol"
import OurServices from "../components/our-services"
import Supply from "../components/supply"
import Product from "../components/product"
import Contact from "../components/contact"
import Brands from "../components/brands"

const IndexPage = () => (
  <Layout>
    <SEO title="Serchill | Glycol Chilling Systems" description="For over 30 years Serchill has worked with our clients to design, build and service their Glycol and chilled water refrigeration systems." />
    <Welcome />
    <WhatOffer />
    <BlueSymbol />
    <OurServices />
    <Supply />
    <Product />
    <Contact />
    <Brands />
    <Footer />
  </Layout>
)

export default IndexPage


import React, { Component } from 'react'


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}


class Form extends Component {

  state = {
    name: '',
    email: '',
    inquiry: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({ result: 'success' }))
      .catch(error => this.setState({ result: 'fail', msg: error }))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'contact',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<p className='contact__success'>Thanks for getting in contact with us.<br /> A member of our team will be in touch soon.</p>)

    return (
      <form {...props}>
        <div>
          <input className="contact__form__text" type="text" name="name" placeholder="Name" onChange={this.handleChange} required />
          <input className="contact__form__text" type="email" name="email" placeholder="Email" onChange={this.handleChange} required />
        </div>
        <textarea className="contact__form__inquiry" name="inquiry" placeholder="Inquiry" onChange={this.handleChange} required ></textarea>
        <div>
          <button className="button">Send</button>
        </div>
      </form>
    )
  }
}



class Contact extends Component {

  render() {

    return (

      <section className="contact">
        <div className="contact__inner">
          <div className="contact__title">
            <h2>Contact</h2>
            <p>If you have any questions about our services, get in contact with us today. </p>
          </div>
          <div className="contact__area">
            <div className="contact__info">
              <h3>Address</h3>
              <p>PO Box 2072<br /> Seaford BC Victoria 3198</p>
              <h3>Phone</h3>
              <p>+61 3 9773 6459</p>
              <h3>Email</h3>
              <p>info@serchill.com.au<br />accounts@serchill.com.au</p>
            </div>
            <Form />

          </div>
        </div>
      </section>

    )
  }
}


export default Contact




